@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@layer base {
	html {
		font-family: "Montserrat", sans-serif;
		color: #27272a;
	}

	h1 {
		@apply text-4xl font-bold text-fontDefault-950;
	}

	h2 {
		@apply text-3xl font-semibold text-fontDefault-800;
	}

	h3 {
		@apply text-2xl font-medium text-fontDefault-600;
	}

	p {
		@apply text-fontDefault-500;
	}
}

@layer components {
	.btn-default-blue {
		@apply block w-full mt-4 px-3.5 py-2.5 font-semibold rounded-md text-white shadow-sm bg-uiDefault-500 hover:bg-uiDefault-600 active:bg-uiDefault-700 text-center text-sm disabled:bg-uiDefault-100 disabled:text-uiDefault-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-uiDefault-600;
	}
}

@layer directives {
	.flex-center {
		@apply flex justify-center items-center;
	}
}

.filterButtonMobile > .overflow-hidden {
	overflow: visible;
}

.x-small-column {
	width: 50px; /* Of een andere vaste breedte naar keuze */
}

.icon-small-column {
	width: 60px; /* Of een andere vaste breedte naar keuze */
}

.small-column {
	width: 120px; /* Of een andere vaste breedte naar keuze */
}

.medium-column {
	width: 250px; /* Pas aan op basis van je behoeften */
}

.large-column {
	max-width: 500px;
	width: auto; /* Laat deze kolom de resterende ruimte opvullen */
}

/* Responsive aanpassingen */
@media (max-width: 768px) {
	.small-column,
	.medium-column {
		width: 175px; /* Kleiner op mobiele apparaten */
	}

	.x-small-column {
		width: 50px;
	}

	.large-column {
		width: 300px; /* Laat deze kolom de resterende ruimte opvullen */
	}
}

.conversation {
	display: flex;
	flex-direction: column;
	max-width: 600px; /* Aanpassen naar wens */
	margin: 20px auto;
}

.messageRow {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px 0;
	gap: 5px;
}

.message,
.answer {
	flex-basis: 48%; /* Zorgt ervoor dat berichten en antwoorden elk ongeveer de helft van de breedte innemen */
	padding: 10px;
	border-radius: 5px;
	border-radius: 15px;
	cursor: pointer;
}

.message {
	background-color: #f0f0f0;
	left: -15px;
	position: relative;
	border-top-left-radius: 0px;
}

.answer {
	background-color: #e7f5fe;
	right: -15px;
	position: relative;
	border-top-right-radius: 0px;
}

.password-criteria {
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
	max-height: 0;
}

.password-criteria.show {
	max-height: 200px; /* Pas dit aan op basis van de inhoud van je component */
}

.uiGradient-radial {
	background: radial-gradient(
		circle at 41.7% 23.8%,
		rgb(255 255 255) 0%,
		rgb(0 174 219 / 2%) 86%
	);
}

.uiGradient-linear {
	background: linear-gradient(
		to top,
		rgba(0, 174, 219, 0.03) 0%,
		rgb(255, 255, 255) 100%
	);
}

.summaryField strong {
	font-weight: 600;
}

.loader-dots {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-dot {
	background-color: #33bee2;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	margin: 0 2px;
	animation: bubble 1.5s infinite ease-in-out both;
}

.loader-dot:nth-child(1) {
	animation-delay: -0.3s;
}
.loader-dot:nth-child(2) {
	animation-delay: -0.15s;
}
.loader-dot:nth-child(3) {
	animation-delay: 0s;
}

@keyframes bubble {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}
